export const WEBSITE_URL = "https://www.buzzbike.cc";
export const PRIVACY_PAGE_URL = WEBSITE_URL + "/privacy";

export const LOWEST_SUBSCRIPTION_PRICE: number | undefined = undefined;

export const NOTICE_PERIOD_DAYS = 30;

export const INVALID_POSTCODE_MESSAGE =
  "We’re sorry to say that our delivery zones don’t cover your address at the moment, so we won’t be able to deliver to you at this time!";

export const RETENTION_DISCOUNT_20_PERCENT = {
  discountCode: "cancelflow20",
  discountPercent: 20,
  discountMonthLength: undefined,
};
export const RETENTION_DISCOUNT_50_PERCENT = {
  discountCode: "cancelflow50",
  discountPercent: 50,
  discountMonthLength: 2,
};
export const RETENTION_DISCOUNT_33_PERCENT = {
  discountCode: "cancelflow33",
  discountPercent: 33,
  discountMonthLength: 2,
};

export const PAUSE_SUBSCRIPTION = { chargeAmountInPence: 1500, months: 1 };

export const BUZZBIKE_E_NAMES = ["BUZZBIKE-E", "BUZZBIKE-EZ"];

export const SOFTWARE_UPDATE_ISSUE = {
  categoryId: "bbez-software-update",
  categoryLabel: "EZ Software Update",
};

export enum DeliveryOption {
  DeliveryToDoor = "DeliveryToDoor",
  PickUp = "PickUp",
}

export enum CollectionOption {
  CollectFromYou = "CollectFromYou",
  DropOff = "DropOff",
}

export const FIRST_WEEK_TIME_SLOTS = [
  "09:00-10:00",
  "10:00-11:00",
  "11:00-12:00",
  "12:00-13:00",
  "13:00-14:00",
  "14:00-15:00",
  "15:00-16:00",
  "16:00-17:00",
  "17:00-18:00",
];

export const OTHER_WEEK_TIME_SLOTS = [
  "09:00-10:00",
  "10:00-11:00",
  "11:00-12:00",
  "12:00-13:00",
  "13:00-14:00",
  "14:00-15:00",
];

export const BBEZ_WAITLIST = false;
export const BBEZ_WAITLIST_EXCLUDE_CITY = "Manchester";

export const VERIFICATION_CONTACT_RH_LINK =
  "https://buzzbike.kustomer.support/en_gb/contact/verification-HJotRExu3";

export enum TheftCoverageType {
  Full = "Full",
  Basic = "Basic",
}

export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

export const BIKE_IDS = IS_PRODUCTION
  ? {
      EZ: 17,
      CTY: 5,
    }
  : {
      EZ: 39,
      CTY: 7,
    };

export const CITIES = {
  "City:1": {
    __typename: "City",
    id: 1,
    country: "United Kingdom",
    currency: "GBP",
    isDefault: true,
    name: "London",
    timezone: "Europe/London",
    postalAreaList: "E EC IG N WC BR CR HA NW SE SM SW UB W",
    postalDistrictList:
      "EN1 EN2 EN3 EN4 EN5 RM1 RM2 RM3 RM4 RM5 RM6 RM7 RM8 RM9 RM10 RM11 RM12 RM13 RM19 WD2 WD3 WD5 WD6 WD7 WD17 WD18 WD19 WD23 WD24 WD25 TW1 TW2 TW3 TW4 TW5 TW6 TW7 TW8 TW9 TW10 TW11 TW12 TW13 TW14 TW15 TW16 TW17 TW18 KT1 KT2 KT3 KT4 KT5 KT6 KT7 KT8 KT9 KT10 KT11 KT12 KT13 KT17 KT18 KT19 KT20 KT21 DA1 DA5 DA6 DA7 DA8 DA14 DA15 DA16 DA17 DA18",
  },
  "City:2": {
    id: 2,
    __typename: "City",
    country: "United Kingdom",
    currency: "GBP",
    isDefault: false,
    name: "Manchester",
    timezone: "Europe/London",
    postalAreaList: "M OL",
    postalDistrictList:
      "BL1 BL2 BL3 BL4 BL5 SK1 SK2 SK3 SK4 SK5 SK6 SK7 SK8 WA14 WA15",
  },
};

export const BIKES = [
  {
    __typename: "BikeType",
    bikeId: BIKE_IDS.CTY,
    name: "Buzzbike-CTY",
    variationName: "Buzzbike | 3 speed",
    shortDescription: "The ideal city bike from £18.99/mo",
    images: [
      {
        __typename: "KitImageType",
        isFeatured: null,
        thumbnailImageUrl: null,
        imageUrl:
          "https://buzzbike-assets.s3.eu-west-1.amazonaws.com/kit_images/a5ecd569-3a64-40fa-84b9-8d9df34e22f7",
      },
    ],
  },
  {
    __typename: "BikeType",
    bikeId: BIKE_IDS.EZ,
    name: "Buzzbike-EZ",
    variationName: "Easy",
    shortDescription: "The ultimate urban  e-bike from £59.99/mo",
    images: [
      {
        imageUrl:
          "https://buzzbike-assets.s3.eu-west-1.amazonaws.com/kit_images/c5d7c051-45cc-4245-946a-dfad0bfd94b6",
        __typename: "KitImageType",
        isFeatured: null,
        thumbnailImageUrl: null,
      },
    ],
  },
];

export const PRICING_PACKAGES = {
  [`pricingPackages({"bikeId":${BIKE_IDS.EZ},"cityId":1,"packageGroupName":"standard-group"})`]:
    {
      __typename: "PricingPackages",
      defaultPackage: {
        __typename: "Package",
        packageSlug: "bbez-12m-6999",
        appliedDiscountCode: null,
        basePrice: 6999,
        bike: BIKES[1],
        collectionFeeInPence: 1500,
        comparativeBasePrice: null,
        couponToApplyAtEndOfMinimumTerm: null,
        deliveryFeeInPence: 1000,
        description: "£69.99 / month",
        noMaintenance: null,
        paidMonthly: true,
        savingAmount: 12000,
        setupFeeInPence: null,
        stickerPrice: 6999,
        termLengthMonths: 12,
      },
      otherPackages: [
        {
          __typename: "Package",
          packageSlug: "bbez-6m-7499",
          appliedDiscountCode: null,
          basePrice: 7499,
          bike: BIKES[1],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£74.99 / month",
          noMaintenance: null,
          paidMonthly: true,
          savingAmount: 3000,
          setupFeeInPence: null,
          stickerPrice: 7499,
          termLengthMonths: 6,
        },
        {
          __typename: "Package",
          packageSlug: "bbez-rolling-7999",
          appliedDiscountCode: null,
          basePrice: 7999,
          bike: BIKES[1],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£79.99 / month",
          paidMonthly: true,
          savingAmount: null,
          setupFeeInPence: null,
          stickerPrice: 7999,
          termLengthMonths: null,
        },
      ],
    },
  [`pricingPackages({"bikeId":${BIKE_IDS.EZ},"cityId":2,"packageGroupName":"standard-group"})`]:
    {
      __typename: "PricingPackages",
      defaultPackage: {
        __typename: "Package",
        packageSlug: "bbez-12m-6999",
        appliedDiscountCode: null,
        basePrice: 6999,
        bike: BIKES[1],
        collectionFeeInPence: 1500,
        comparativeBasePrice: null,
        couponToApplyAtEndOfMinimumTerm: null,
        deliveryFeeInPence: 1000,
        description: "£69.99 / month",
        noMaintenance: null,
        paidMonthly: true,
        savingAmount: 12000,
        setupFeeInPence: null,
        stickerPrice: 6999,
        termLengthMonths: 12,
      },
      otherPackages: [
        {
          __typename: "Package",
          packageSlug: "bbez-6m-7499",
          appliedDiscountCode: null,
          basePrice: 7499,
          bike: BIKES[1],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£74.99 / month",
          noMaintenance: null,
          paidMonthly: true,
          savingAmount: 3000,
          setupFeeInPence: null,
          stickerPrice: 7499,
          termLengthMonths: 6,
        },
        {
          __typename: "Package",
          packageSlug: "bbez-rolling-7999",
          appliedDiscountCode: null,
          basePrice: 7999,
          bike: BIKES[1],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£79.99 / month",
          paidMonthly: true,
          savingAmount: null,
          setupFeeInPence: null,
          stickerPrice: 7999,
          termLengthMonths: null,
        },
      ],
    },
  [`pricingPackages({"bikeId":${BIKE_IDS.CTY},"cityId":1,"packageGroupName":"standard-group"})`]:
    {
      __typename: "PricingPackages",
      defaultPackage: {
        __typename: "Package",
        packageSlug: "bbcty-12m-min-1899",
        appliedDiscountCode: null,
        basePrice: 1899,
        bike: BIKES[0],
        collectionFeeInPence: 1500,
        comparativeBasePrice: null,
        couponToApplyAtEndOfMinimumTerm: null,
        deliveryFeeInPence: 1000,
        description: "£18.99 / month (£10 delivery, £15 collection)",
        paidMonthly: true,
        savingAmount: 10800,
        setupFeeInPence: null,
        termLengthMonths: 12,
        stickerPrice: 1899,
      },
      otherPackages: [
        {
          __typename: "Package",
          packageSlug: "bbcty-6m-min-2399",
          appliedDiscountCode: null,
          basePrice: 2399,
          bike: BIKES[0],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£23.99 / month (£10 delivery, £15 collection)",
          paidMonthly: true,
          savingAmount: 2400,
          setupFeeInPence: null,
          stickerPrice: 2399,
          termLengthMonths: 6,
        },
        {
          __typename: "Package",
          packageSlug: "bbcty-rolling-2799",
          appliedDiscountCode: null,
          basePrice: 2799,
          bike: BIKES[0],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£27.99 / month (£10 delivery, £15 collection)",
          paidMonthly: true,
          savingAmount: null,
          setupFeeInPence: null,
          stickerPrice: 2799,
          termLengthMonths: null,
        },
      ],
    },
  [`pricingPackages({"bikeId":${BIKE_IDS.CTY},"cityId":2,"packageGroupName":"standard-group"})`]:
    {
      __typename: "PricingPackages",
      defaultPackage: {
        __typename: "Package",
        packageSlug: "bbcty-12m-min-1899",
        appliedDiscountCode: null,
        basePrice: 1899,
        bike: BIKES[0],
        collectionFeeInPence: 1500,
        comparativeBasePrice: null,
        couponToApplyAtEndOfMinimumTerm: null,
        deliveryFeeInPence: 1000,
        description: "£18.99 / month (£10 delivery, £15 collection)",
        paidMonthly: true,
        savingAmount: 10800,
        setupFeeInPence: null,
        termLengthMonths: 12,
        stickerPrice: 1899,
      },
      otherPackages: [
        {
          __typename: "Package",
          packageSlug: "bbcty-6m-min-2399",
          appliedDiscountCode: null,
          basePrice: 2399,
          bike: BIKES[0],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£23.99 / month (£10 delivery, £15 collection)",
          paidMonthly: true,
          savingAmount: 2400,
          setupFeeInPence: null,
          stickerPrice: 2399,
          termLengthMonths: 6,
        },
        {
          __typename: "Package",
          packageSlug: "bbcty-rolling-2799",
          appliedDiscountCode: null,
          basePrice: 2799,
          bike: BIKES[0],
          collectionFeeInPence: 1500,
          comparativeBasePrice: null,
          couponToApplyAtEndOfMinimumTerm: null,
          deliveryFeeInPence: 1000,
          description: "£27.99 / month (£10 delivery, £15 collection)",
          paidMonthly: true,
          savingAmount: null,
          setupFeeInPence: null,
          stickerPrice: 2799,
          termLengthMonths: null,
        },
      ],
    },
};

export const EXPERIMENT_CODE = "NOMAINTENANCECANCELFLOWQ32024";
